export default [
	{
	  title: "Administracion",
	  icon: "ShieldIcon",
	  children: [
		 {
			title: 'Usuarios',
			route: 'administracion-usuarios',
			icon: 'CircleIcon',
		 }
	  ],
	},
  ];