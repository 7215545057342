<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span
        class="ml-25"
      >LRCDigital</span>
      <span class="d-none d-sm-inline-block">, Todos los derechos reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">Version 1.0 
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
