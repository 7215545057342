export default [
    {
      title: "Movimientos",
      icon: "ClipboardIcon",
    children: [
        {
          title: 'Captura de Movimientos',
          route: 'mov-captura-movimientos',
          icon: 'CalendarIcon',
          action: 'read',
          resource: 'Checador',
        },
        {
          title: 'Captura de Gastos',
          route: 'mov-captura-gastos',
          icon: 'DollarSignIcon',
        },
        {
          title: 'Reporte de Movimientos',
          route: 'mov-reportes-movimientos',
          icon: 'PrinterIcon',
          action: 'read',
          resource: 'Reportes',
        },
        {
          title: 'Reporte de Mallas',
          route: 'mov-reportes-mallas',
          icon: 'PrinterIcon',
          action: 'read',
          resource: 'Reportes',
        },
      ],
    },
  ];
