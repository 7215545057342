export default [
	{
	  title: "Checador",
	  icon: "SpeakerIcon",
	  children: [
		 {
			title: 'Asistencia Centro Trabajo',
			route: 'checador-registro',
			icon: 'UserCheckIcon',
			action: 'read',
        	resource: 'Checador',
		 },
		 {
			title: 'Asistencia General',
			route: 'checador-general',
			icon: 'SpeakerIcon',
			action: 'read',
        	resource: 'Checador',
		 },
	  ],
	},
  ];